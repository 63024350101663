// Deps
import { useState, useEffect, useRef, useCallback } from 'react'

// Sections
import Breadcrumbs from '/views/sections/breadcrumbs'
import Listing from '/views/sections/listing'
import ProductCarousel from '/views/sections/product-carousel'

// Functions
import isExact from '@wearetla/tla-essentials-tools/functions/is-exact';

// Hooks
import useQuery from '/hooks/query'

// Services
import productServices from '/services/product'
import siteServices from '/services/site'

// Context
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state'
import { useNavigator, useParams } from '@wearetla/tla-essentials-tools/utilities/navigator'
import { useHead } from '@wearetla/tla-essentials-tools/utilities/head';
import { useGlobalEvents } from '@wearetla/tla-essentials-tools/utilities/global-events';

// Shared Endpoints
const getProducts = (brandID, query) => {
	return productServices.getBrandProducts(brandID, query);
}

const getBrandFilters = (brandID) => {
	return productServices.getBrandFilters(brandID);
}

const getRecentProducts = () => {
	return siteServices.getLastVisitedProducts();
}

const generateHead = (brandData) => {
	return {
		title: brandData.seo_title ? brandData.seo_title : `${brandData.name} Ürünleri & Fiyatları`,
		description: brandData.seo_description ? brandData.seo_description : brandData.description,
	}
}

const defaultQuery = {
	page: '1',
	order: 'ovd',
}

const Brand = ({ match }) => {
	const _mounted = useRef(false);
	
	const { setError /*, setNotFound*/ } = useGlobalState();
	const { getRoutePath, redirect } = useNavigator();
	const { triggerEvent } = useGlobalEvents();
	const { setHead } = useHead();

	const { id: listID, query: queryString, slug: listSlug } = (match.params ?? {});
	
	const paramsCache = useRef(match.params);

	
	const [query] = useQuery(queryString, defaultQuery, undefined, undefined, undefined, listID);
	
	const [filters, setFilters] = useState(null);
	const [pagination, setPagination] = useState(false);
	const [products, setProducts] = useState(false);
	const [brandData, setBrandData] = useState(false);
	
	// Supporting Data
	const [recentProducts, setRecentProducts] = useState(false);
	
	const fetchedBrandID = useRef(false);
	const fetchedQuery = useRef(false);

	const getData = (query, brandID) => {
		if(brandID) {
			setProducts(false);
			getProducts(brandID, query).then((payload) => {
				if (_mounted.current) {
					fetchedQuery.current = query;
					fetchedBrandID.current = brandID;
					setPagination(payload.pagination);
					setProducts(payload.products);
					setBrandData(payload.brandData);
				}
			}).catch((e) => {
				if (_mounted.current) {
					setError();
					console.warn('Brand products fetch error', e);
				}
			})
		}
	};

	const getFilters = (brandID) => {
		setFilters(false);
		getBrandFilters(brandID).then((payload) => {
			if (_mounted.current) {
				setFilters(payload);
			}
		}).catch(() => {
			if (_mounted.current) {
				setError();
			}
		})
	}

	const updateQuery = useCallback((updatedQuery) => {
		let newQuery = [];

		for (const param in updatedQuery) {
			if (param !== 'primaryVariable' && updatedQuery[param].length && defaultQuery[param] !== updatedQuery[param]) {
				newQuery.push(param + '=' + updatedQuery[param]);
			}
		}

		redirect(
			'brand',
			{
				...paramsCache.current,
				query: (newQuery.length ? newQuery.join('&') : undefined),
			},
			undefined,
			undefined,
			false
		);
	}, []);

	useEffect(() => {
		_mounted.current = true;

		return () => {
			_mounted.current = false;
		}
	}, [])

	useEffect(() => {
		paramsCache.current = match.params;
	}, [match.params])

	useEffect(() => {
		if(_mounted.current) {
			const {primaryVariable, ...newQuery} = query;

			const idChanged = primaryVariable !== fetchedBrandID.current;
	
			if (primaryVariable && (!isExact(newQuery, fetchedQuery.current) || idChanged)) {
				getData(newQuery, primaryVariable);
	
				if(idChanged){
					setPagination(false);
					getFilters(primaryVariable);
				}
			}
		}
	}, [query])

	useEffect(() => {
		if(brandData) {
			setHead(generateHead(brandData, getRoutePath))
		}
	}, [brandData])

	// Supporting Data
	useEffect(() => {
		if (_mounted.current && !recentProducts) {
			getRecentProducts().then((payload) => {
				if (_mounted.current) {
					setRecentProducts(payload);
				}
			}).catch(() => {
			})
		}
	}, [recentProducts]);

	// Analytics
	useEffect(() => {
		if(products && brandData) {
			triggerEvent('listingDataUpdate', {
				key: 'Marka Sayfası: ' + brandData.name,
				products
			});
		}
	}, [products, brandData])


	return (
		<main className="page listing">
			<Breadcrumbs data={brandData ? [{name: `${brandData.name} Ürünleri & Fiyatları`}] : false} />
			<Listing
				page="search"
				onUpdateQuery={updateQuery}
				query={query}
				products={products}
				pagination={pagination}
				filters={filters ?? false}
				title={brandData ? `${brandData?.name} Ürünleri & Fiyatları` : false}
				description={false} />
			<ProductCarousel
				tabs
				className="listing-recentproducts"
				data={[
					{
						title: 'Son Gezilen Ürünler',
						icon: 'history',
						key: 'recent',
						products: recentProducts ? recentProducts : false,
					}
				]} />
		</main>
	)
}

Brand.defaultProps = {
}

export default Brand;